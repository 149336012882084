.astitav_admission_downloads {
  display: grid;
  grid-template-columns: auto;
  place-items: center;
  padding: 4rem 1rem;
  /* gap: 4rem; */
  row-gap: 2rem;
  column-gap: 0.5rem;
  position: relative;
}
.astitav_admission_download-card {
  padding: 1.5rem;
  border: 2px solid var(--color-news-bg);
  width: 80%;
  height: 15rem;
  border-radius: 0.1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.103),
    0 6px 20px 0 rgba(0, 0, 0, 0.097);
  display: grid;
  gap: 1rem;
  z-index: 8;
}
.astitav_admission_download-card img {
  width: 15%;
}

.astitav_admission_download-card_title {
  color: var(--color-dark-red);
  font-family: var(--font-family);
  font-size: 1.5rem;
  font-weight: 400;
}
.astitav_admission_download-btn {
  font-family: var(--font-family-inder);
  font-size: 0.9375rem;
  font-weight: 600;
  margin-top: auto;
  cursor: pointer;
}
@media screen and (min-width: 700px) {
  .astitav_admission_downloads {
    grid-template-columns: auto auto;
  }
  .astitav_admission_download-card {
    max-width: 25rem;
    height: 15rem;
  }
}

@media screen and (min-width: 1200px) {
  .astitav_admission_downloads {
    grid-template-columns: auto auto auto;
    padding: 2rem;
    row-gap: 4rem;
  }
  .astitav_admission_download-card {
    width: 24rem;
    height: 16rem;
  }
  .astitav_admission_download-card img {
    width: auto;
  }

  .astitav_admission_download-card_title {
    font-size: 1.875rem;
  }
  .astitav_admission_download-btn {
    font-weight: 400;
  }
}
