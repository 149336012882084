.astitav-Founder_container {
  padding: 6rem;
}
.astitav-Founder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-image: url(../../assets/images/Founder/bg.png);
  background-repeat: no-repeat, repeat;
}
.astitav-Founder_image {
  width: 40%;
}
.astitav-Founder_content {
  display: flex;
  flex-direction: column;
}
.astitav-Founder_content-heading {
  color: var(--color-dark-red);
  font-family: var(--font-family);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.astitav-Founder_content-heading1 {
  color: var(--color-dark-red);
  font-family: var(--font-family-inder);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.astitav-Founder_content-paragraph {
  color: #7b7575;
  font-family: var(--font-family-inder);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 20px;
  max-width: 600px;
}

@media screen and (max-width: 1200px) {
  .astitav-Founder_content-heading {
    font-size: 40px;
  }
  .astitav-Founder_content-paragraph {
    font-size: 15px;

    width: 100%;
  }
  .astitav-Founder_image-img {
    max-width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .astitav-Founder_content-heading {
    padding-bottom: 1rem;
  }
  .astitav-Founder_content-paragraph {
    font-size: 15px;

    width: 100%;
  }
  .astitav-Founder_image-img {
    max-width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .astitav-Founder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url(../../assets/images/Founder/bg.png);
    background-repeat: repeat;
  }

  .astitav-Founder_image-img {
    max-width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .astitav-Founder_content-heading {
    font-size: 30px;
  }

  .astitav-Founder_content-heading1 {
    font-size: 25px;
  }
  .astitav-Founder_content-paragraph {
    font-size: 15px;

    width: 100%;
  }

  .astitav-Founder_image-img {
    width: 250%;
    height: fit-content;
  }
}
