/* //Color-circles */
.astitav_color-circles-div {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  overflow: hidden;
  left: 0;
  opacity: 0.3;
}
.astitav_color-circles-div > * {
  border-radius: 50%;
  position: absolute;
}
.astitav_color-circles-cream {
  left: 4rem;
  top: 3rem;
  padding: 1.3rem;
  background-color: var(--color-cream);
}
.astitav_color-circles-red {
  padding: 0.8rem;
  left: 6rem;
  bottom: 11rem;
  background-color: var(--color-orange-red);
}
.astitav_color-circles-aqua {
  padding: 1.3rem;
  right: 4rem;
  top: 1rem;
  background-color: var(--color-aqua);
}
.astitav_color-circles-aqua2 {
  padding: 0.4rem;
  left: 2rem;
  bottom: 3rem;
  background-color: var(--color-aqua);
}
.astitav_color-circles-purple {
  right: 3rem;
  bottom: 6rem;
  padding: 0.5rem;
  background-color: var(--color-purple);
}
.astitav_color-circles-green {
  left: 3rem;
  top: 9rem;
  padding: 0.5rem;
  background-color: var(--color-green);
}

@media screen and (min-width: 850px) {
  /* //Color-circles */
  .astitav_color-circles-div {
  opacity: 0.9;
  }
  .astitav_color-circles-red {
    left: 9rem;
    bottom: 5rem;
  }
  .astitav_color-circles-aqua {
    top: 3rem;
  }
  .astitav_color-circles-aqua2 {
    padding: 0.2rem;
  }
}
