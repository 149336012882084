.astitav_therapies {
  padding: 2rem;
  display: grid;
}
.astitav_therapies_title,
.astitav_therapies_desc1 {
  font-family: var(--font-family);
  font-size: 3.75rem;
  color: var(--color-dark-red);
}
.astitav_therapies_desc1 {
  font-size: 1.5625rem;
  margin-inline-start: auto;
  width: fit-content;
}
.astitav_therapies_desc-div,
.astitav_therapies_desc1_div {
  display: grid;
  gap: 1rem;
}
.astitav_therapies_content {
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  /* border: 2px solid red; */
}

.astitav_therapies_desc2 {
  color: var(--color-text);
}

.astitav_therapies_btn {
  border: 2px solid var(--color-dark-red);
  padding: 0.5rem 1rem;
  color: var(--color-dark-red);
  margin-inline-end: auto;
  border-radius: 0.5rem;
  font-size: 0.85rem;
  font-weight: 600;
  background: none;
  cursor: pointer;
  font-family: var(--font-family);
}
.astitav_therapies_people-img {
  display: none;
}
.astitav_therapies_list-div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  padding: 1rem;
}
.astitav_therapy_div {
  text-align: center;
}
.astitav_therapies_list-div-desktop {
  display: none;
}
.astitav_therapy_div img {
  width: 100%;
}
.astitav_therapy_div p {
  font-size: 0.75rem;
  font-weight: 600;
}

@media screen and (min-width: 650px) {
  .astitav_therapies {
    gap: 0rem;
    margin: 2rem 0;
  }
  .astitav_therapies_title,
  .astitav_therapies_desc1 {
    font-size: 8.125rem;
    text-align: start;
    width: 50%;
  }
  .astitav_therapies_desc1_div {
    /* border: 2px solid red; */
    width: 100%;
    margin-inline-start: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .astitav_therapies_desc1 {
    font-size: 4rem;
    text-align: start;
    width: 100%;
  }
  .astitav_therapies_content {
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .astitav_therapies_desc-div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
  }

  .astitav_therapies_desc2 {
    color: var(--color-text);
    font-size: 1.25rem;
  }

  .astitav_therapies_btn {
    font-size: 0.9rem;
  }
  .astitav_therapies_people-img {
    display: block;
    width: 100%;
  }
  .astitav_therapies_list-div {
    display: none;
  }
  .astitav_therapies_list-div-desktop {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    padding: 1rem;
    /* border: 2px solid red; */
  }
  .astitav_therapy_div img {
    width: 50%;
  }
  .astitav_therapies_div-group {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 2rem;
    /* border: 2px solid red; */
  }
}

@media screen and (min-width: 1000px) {
  .astitav_therapies {
    gap: 0rem;
    margin: 2rem 0;
  }
  .astitav_therapies_title,
  .astitav_therapies_desc1 {
    text-align: end;
  }
  .astitav_therapies_desc1_div {
    width: 50%;
  }
  .astitav_therapies_desc1{
    text-align: start;

  }
  .astitav_therapies_content {
    flex-direction: row;
  }

  .astitav_therapy_div {
    text-align: center;
  }
  .astitav_therapy_div p {
    font-size: 0.75rem;
    font-weight: 600;
  }
}
