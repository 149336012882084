.astitav_landing-hero {
  background-image: url("../../assets/images/landing/hero/heroBg.svg");
  height: 28rem;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.astitav_landing_hero_content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
  /* border: 2px solid red; */
}
.astitav_landing_hero_graphic {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 98%;
  z-index: 1;
}
.astitav_landing_hero_girl1,
.astitav_landing_hero_boy {
  display: none;
}

.astitav_landing_hero_center-div {
  position: relative;
  text-align: center;
}
.astitav_landing_hero_center-circle {
  width: 70%;
}
.astitav_landing_hero_center-girl2 {
  position: absolute;
  right: 0.5rem;
  top: 1rem;
  bottom: 0;
  margin: auto 0;
  width: 45%;
}
.astitav_landing_hero_cream-circle,
.astitav_landing_hero_purple-circle,
.astitav_landing_hero_red-circle,
.astitav_landing_hero_aqua-circle {
  border-radius: 50%;
  position: absolute;
}
.astitav_landing_hero_cream-circle {
  background-color: var(--color-cream);
  padding: 0.6rem;
  top: 6.5rem;
  left: 3rem;
}
.astitav_landing_hero_purple-circle {
  background-color: var(--color-purple);
  padding: 0.55rem;
  top: 7rem;
  right: 7rem;
}
.astitav_landing_hero_red-circle {
  background-color: var(--color-orange-red);
  padding: 0.35rem;
  bottom: 4rem;
  left: 10rem;
}
.astitav_landing_hero_aqua-circle {
  background-color: var(--color-aqua);
  padding: 0.3rem;
  bottom: 6rem;
  left: 2rem;
}

@media screen and (min-width: 650px) {
  .astitav_landing_hero_content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 60%;
    padding: 0 2rem;
  }

  .astitav_landing_hero_girl1,
  .astitav_landing_hero_boy {
    display: block;
    width: 15%;
  }
  .astitav_landing_hero_boy {
    width: 20%;
    margin: auto 0;
  }

  .astitav_landing_hero_center-div {
    position: relative;
    /* border: 2px solid red; */
    text-align: center;
  }
  .astitav_landing_hero_center-circle {
    width: 70%;
  }
  .astitav_landing_hero_center-girl2 {
    position: absolute;
    right: 1rem;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 40%;
  }
  
}

@media screen and (min-width: 1000px) {
  .astitav_landing-hero {
    height: 40rem;
    background-size: cover;
    background-repeat: repeat-x;
    background-position-y: bottom;
  }
  .astitav_landing_hero_content {
    justify-content: space-between;
    align-items: center;

    height: 100%;
  }

  .astitav_landing_hero_girl1 {
    margin-top: auto;
    margin-bottom: 0.8rem;
  }
  .astitav_landing_hero_girl1,
  .astitav_landing_hero_boy {
    display: block;
  }

  .astitav_landing_hero_center-div {
    position: relative;
  }
  .astitav_landing_hero_center-circle {
    width: auto;
  }
  .astitav_landing_hero_center-girl2 {
    right: -7rem;
    top: 0;
    width: auto;
  }
  .astitav_landing_hero_boy {
    width: 28%;
    margin: auto 0;
  }

  .astitav_landing_hero_cream-circle {
    padding: 1.5rem;
    top: 8rem;
    left: 8rem;
  }
  .astitav_landing_hero_purple-circle {
    top: 15rem;
    right: 15rem;
  }
  .astitav_landing_hero_red-circle {
    padding: 0.4rem;
    bottom: 18rem;
    left: 5rem;
  }
  .astitav_landing_hero_aqua-circle {
    padding: 0.6rem;
    bottom: 10rem;
    left: 16rem;
  }
}
