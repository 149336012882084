.astitav_internship_content{
  position: relative;

}
.astitav_internship_form {
  width: 90%;
  padding: 4rem 0;
  margin: 0 auto;
  display: grid;
  gap: 2rem;
  position: relative;
  z-index: 8;
}
.astitav_internship_form input {
  width: 100%;
  padding: 1rem;
  border-radius: 0.3125rem;
  border: 1px solid var(--color-gray);
}
.astitav_internship_form_desc {
  font-family: var(--font-family-inder);
  font-size: 1rem;
}
.astitav_internship_form_double-input-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.astitav_internship_form_radio-div {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.astitav_internship_form_radio-div-title {
  font-size: 0.85rem;
  font-weight: 600;
}
.astitav_internship_form_input-div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.astitav_internship_form_input-div label {
  font-size: 0.9rem;
}
.astitav_internship_form_resume-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-inline-end: auto;
  gap: 1rem;
  width: 100%;
}
.astitav_internship_form_resume-div p {
  font-size: 0.8rem;
  font-weight: 600;
}
.astitav_internship_form_resume-upload-btn-div {
  border: 1px solid var(--color-gray);
  border-radius: 0.3125rem;
  padding: 0.8rem 1.8rem;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
.astitav_internship_form_resume-upload-icon {
  font-size: 1rem !important;
}
.astitav_internship_form button {
  border: 1px solid var(--color-dark-red);
  padding: 0.8rem 1.5rem;
  color: var(--color-dark-red);
  margin-inline-end: 0;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  background: none;
  font-family: var(--font-family);
  cursor: pointer;
  margin: 0 auto;
}
.astitav_internship_form button:hover {
  background-color: var(--color-dark-red);
  color: var(--color-white);
}

input[type="file"] {
  display: none;
}

@media screen and (min-width: 700px) {
  .astitav_internship_form {
    width: 75%;
    padding: 4rem;
  }
  .astitav_internship_form_desc {
    font-size: 1.25rem;
  }
  .astitav_internship_form_double-input-div {
    flex-direction: row;
  }
  .astitav_internship_form_radio-div-title {
    font-size: 0.75rem;
  }
  .astitav_internship_form_input-div label {
    font-size: 0.8rem;
  }
  .astitav_internship_form_resume-div {
    gap: 2rem;
    width: auto;
  }
  .astitav_internship_form_resume-upload-btn-div {
    padding: 0.8rem 2rem;
  }
  .astitav_internship_form button {
    margin: 0;
    margin-inline-end: auto;
  }
}
