.astitav_navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  /* border: 2px solid red; */
  z-index: 10;
}

.astitav_navbar_logo img {
  width: 100%;
}

.astitav_navbar-links_li {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  font-size: 0.9rem;
  padding: 0rem 1rem;
  cursor: pointer;
  /* white-space: nowrap; */
  transition: all ease-in 0.2s;
}

.astitav_navbar-links_li_btn {
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-size: 0.9rem;
  color: var(--color-white);
  background-color: var(--color-aqua);
  border-radius: 0.3125rem;
  text-align: center;
  transition: all ease-in 0.3s;
}
.astitav_navbar-links_li_btn:hover {
  background-color: rgb(0, 148, 148);
}

.astitav_navbar-links {
  color: var(--color-dark-red);
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.astitav_navbar-links_li:hover {
  color: var(--color-aqua);
}

.astitav_navbar-links_container {
  display: flex;
  align-items: center;
  gap: 1rem;
  /* border: 2px solid red; */
}

.active {
  border-bottom: 2px solid var(--color-dark-red);
}

.astitav_navbar-menu {
  display: none;
  position: relative;
  /* border: 2px solid red; */
}

/*Dropdown */
.astitav_nav_dropdown {
  position: relative;
  display: inline-block;
  margin-inline-start: auto;
}

.astitav_nav_dropdown-content {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 1.5rem;
  margin: 0 auto;
  background-color: var(--color-white);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  text-align: center;
  gap: 0.8rem;
  border-radius: 0.5rem;
  padding: 0.8rem 0rem;
  white-space: nowrap;
}

.astitav_nav_dropdown:hover .astitav_nav_dropdown-content {
  display: flex;
}

.astitav_navbar-links_li {
  font-weight: 600;
}

.astitav_navbar-links_li:hover {
  color: var(--color-aqua);
}
/* /------/ */

@media screen and (max-width: 1050px) {
  .astitav_navbar {
    padding: 0 1rem;
  }
  .astitav_navbar-links_container {
    display: flex;
    align-items: center;
    gap: 0rem;
  }
  .astitav_navbar-links_li {
    font-size: 0.8rem;
    padding: 0rem 0.8rem;
    cursor: pointer;
    /* white-space: nowrap; */
  }
  .astitav_navbar-links_li_btn {
    padding: 0.4rem 0.9rem;
    font-size: 0.8rem;
    color: var(--color-white);
    background-color: var(--color-aqua);
    border-radius: 0.3125rem;
    text-align: center;
  }
}

@media screen and (max-width: 850px) {
  .astitav_navbar-menu {
    margin-left: 0.5rem;
  }
  .astitav_navbar {
    padding: 0rem 1rem;
  }

  .astitav_navbar_logo {
    margin-inline-end: auto;
  }
  .astitav_navbar_logo img {
    width: 80%;
  }
  .astitav_navbar-links_container {
    display: none;
  }

  .astitav_navbar-links_login-sign_div {
    display: none;
  }

  .astitav_navbar-menu {
    display: flex;
    flex-direction: column;
  }

  .astitav_navbar-menu_container-links {
    position: absolute;
    right: -0.95rem;
    margin: 0.5rem auto;
    background-color: var(--color-white);
    padding: 1rem 1rem;
    border-radius: 0.4rem;
    z-index: 15;
    width: 99vw;
    box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.219);
    /* border: 2px solid red; */
  }

  li {
    text-align: right;
    color: var(--color-dark-red);
    font-family: var(--font-family);
    padding: 0.5rem 0rem;
    font-weight: bold;
  }

  .astitav_navbar_name {
    font-size: 1.6rem;
  }

  .astitav_navbar-menu_toggleBtn {
    font-size: 2rem;
  }
}
