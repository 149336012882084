.astitav_service {
  display: grid;
  grid-template-columns: auto;
  gap: 5rem;
  padding: 6rem 1rem;
  position: relative;
}
.astitav_service_card-main {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /* border: 2px solid red; */
  width: 100%;
  margin-inline: auto;
}
.astitav_service_card {
  position: relative;
  width: 80%;
  min-height: auto;
  background-color: rgba(255, 0, 0, 0.226);
  padding: 3.5rem 2rem 2rem 2rem;
  border-radius: 1rem;
  cursor: pointer;
}
.astitav_service_card img {
  width: 30%;
  position: absolute;
  right: 0;
  left: 0;
  top: -3rem;
  bottom: auto;
  margin: 0 auto;
  cursor: pointer;
}
.astitav_service_card_icon {
  width: 30%;
  position: absolute;
  right: 0;
  left: 0;
  top: -3rem;
  bottom: auto;
  margin: 0 auto;
  cursor: pointer;
  background-color: rgb(199, 0, 93);
  font-size: 2rem;
  color: white;
  border-radius: 50%;
  width: 5.5rem;
  height: 5.5rem;
  padding: 1rem;
}
.astitav_service_card-content-div {
  width: 100%;
  margin-inline-start: auto;
  display: grid;
  gap: 0.6rem;
  text-align: center;
}
.astitav_service_card-title {
  font-size: 1.187rem;
  font-weight: 600;
  font-family: var(--font-family-inder) !important;
}
.astitav_service_card-desc {
  font-size: 0.9rem;
  color: var(--color-gray);
  font-weight: 500;
  font-family: var(--font-family-inder) !important;
}

.astitav_service_graphic_cream-circle,
.astitav_service_graphic_purple-circle,
.astitav_service_graphic_red-circle,
.astitav_service_graphic_aqua-circle,
.astitav_service_graphic_cream-circle2,
.astitav_service_graphic_purple-circle2,
.astitav_service_graphic_red-circle2,
.astitav_service_graphic_aqua-circle2 {
  border-radius: 50%;
  position: absolute;
}
.astitav_service_graphic_cream-circle,
.astitav_service_graphic_cream-circle2 {
  background-color: var(--color-cream);
  padding: 0.9rem;
  top: 4rem;
  left: 2rem;
}
.astitav_service_graphic_purple-circle,
.astitav_service_graphic_purple-circle2 {
  background-color: var(--color-purple);
  padding: 0.7rem;
  top: 2rem;
  right: 3rem;
}
.astitav_service_graphic_red-circle,
.astitav_service_graphic_red-circle2 {
  background-color: var(--color-orange-red);
  padding: 0.35rem;
  bottom: 4rem;
  left: 10rem;
}
.astitav_service_graphic_aqua-circle,
.astitav_service_graphic_aqua-circle2 {
  background-color: var(--color-aqua);
  padding: 0.9rem;
  bottom: 50rem;
  left: 4rem;
}
.astitav_service_graphic_cream-circle2 {
  padding: 0.6rem;
  top: 50rem;
  left: 2rem;
}
.astitav_service_graphic_purple-circle2 {
  padding: 0.55rem;
  top: 40rem;
  right: 7rem;
}
.astitav_service_graphic_red-circle2 {
  padding: 0.35rem;
  bottom: 4rem;
  left: 10rem;
}
.astitav_service_graphic_aqua-circle2 {
  padding: 0.9rem;
  bottom: 6rem;
  left: auto;
  right: 2rem;
}

@media screen and (min-width: 468px) {
  .astitav_service {
    gap: 6rem;
  }
  .astitav_service_card img {
    width: 25%;
    top: -4rem;
  }
}
@media screen and (min-width: 750px) {
  .astitav_service {
    grid-template-columns: auto auto;
  }
  .astitav_service_card {
    width: 100%;
    min-height: 70%;
    height: 18rem;
  }
  .astitav_service_card img {
    top: -4rem;
  }
}

@media screen and (min-width: 1000px) {
  .astitav_service {
    grid-template-columns: auto auto;
    gap: 3rem;
    padding: 6rem;
  }
  .astitav_service_card-main {
    justify-content: flex-end;
    width: 90%;
  }
  .astitav_service_card {
    width: 80%;
    height: 15rem;
    min-height: auto;
    /* height: auto; */
    padding: 2rem 2rem;
  }
  .astitav_service_card img {
    width: 7rem;
    right: 90%;
    left: auto;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
  .astitav_service_card_icon {
    width: 30%;
    right: 90%;
    left: auto;
    top: 0;
    bottom: 0;
    margin: auto 0;
    font-size: 2rem;
    width: 7rem;
    height: 7rem;
    padding: 1rem;
  }
  .astitav_service_card-content-div {
    width: 88%;
    text-align: left;
  }
}
