
.astitav_gallery {
    padding: 3rem 1rem;
  }
  .astitav_gallery_title {
    color: var(--color-dark-red);
    font-family: var(--font-family);
    font-size: 3.75rem;
    text-align: center;
    padding-bottom: 2rem;
  }

.astitav_gallery_images-div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;

}
.astitav_gallery_images-div img {
  width: 80%;
}

@media screen and (min-width: 1000px) {
  .astitav_gallery {
    padding: 6rem;
  }
  .astitav_gallery_title {
    font-size: 6.875rem;
    text-align: start;
    padding-bottom: 4rem;
  }
  .astitav_gallery_images-div {
    justify-content: space-between;
    row-gap: 4rem;
  }
  .astitav_gallery img {
    width: 45%;
  }
}
