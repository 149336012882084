.astitav_footer {
  /* border: 2px solid red; */
  position: relative;
  display: grid;
  margin-top: auto;
  gap: 1rem;
  padding-bottom: 3rem;
}

.astitav_footer_bg {
  /* border: 1px solid red; */
  width: 100%;
  background-color: var(--color-light-gray);
  position: absolute;
  top: 20%;
  /* bottom: 0; */
  /* margin-block: auto; */
  z-index: -5;
}
.astitav_footer_bg-desktop {
  display: none;
}

.astitav_footer_graphics_div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.astitav_footer_tree-sm {
  /* border: 2px solid red; */
  width: 15%;
  margin-bottom: 0.5rem;
}

.astitav_footer_bear {
  /* border: 2px solid red; */
  width: 30%;
}

.astitav_footer_tree-lg {
  /* border: 2px solid red; */
  margin-bottom: 0.5rem;
  display: none;
}

.astitav_footer_center-div {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.astitav_footer_links {
  /* border: 2px solid red; */
  display: flex;
  gap: 2rem;
  padding: 0 1rem;
}
.astitav_footer_links_list {
  /* border: 2px solid red; */
  display: grid;
  gap: 0.5rem;
  font-size: 0.8rem;
  font-weight: 700;
}
.astitav_footer_logo {
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  color: var(--color-dark-red);
  font-family: var(--font-family);
}
.astitav_footer_btn {
  border: 2px solid var(--color-dark-red);
  padding: 0.5rem 1rem;
  color: var(--color-dark-red);
  margin-inline: auto;
  border-radius: 0.5rem;
  font-size: 0.85rem;
  font-weight: 600;
  font-family: var(--font-family);
}
.astitav_footer_social-links-div {
  display: grid;
  gap: 2rem;
}
.astitav_footer_social-div {
  color: var(--color-dark-red);
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.astitav_footer_social-div a {
  display: flex;
}

.astitav_footer_fb-logo,
.astitav_footer_ig-logo,
.astitav_footer_ld-logo {
  border-radius: 50%;
  font-size: 1.5rem;
}
.astitav_footer_ig-logo {
  color: var(--color-white);
  background-color: var(--color-dark-red);
  padding: 0.2rem;
}

.astitav_footer_copyright-text {
  font-size: 0.75rem;
  font-weight: 600;
  text-align: center;
  color: var(--color-black);
  font-family: var(--font-family);
  margin-top: 1rem;
}

/* //For tablet------------ */
@media screen and (min-width: 650px) {
  .astitav_footer {
    overflow: hidden;
  }
  .astitav_footer_bg-desktop {
    display: none;
  }
  .astitav_footer_bg {
    display: block;
    width: 100%;
    background-color: var(--color-light-gray);
    position: absolute;
    bottom: 0;
    z-index: -5;
  }

  .astitav_footer_tree-sm {
    display: none;
  }

  .astitav_footer_bear {
    /* border: 2px solid red; */
    width: 20%;
  }

  .astitav_footer_tree-lg {
    display: block;
    width: 10%;

    /* border: 2px solid red; */
    margin-bottom: 0.5rem;
  }

  .astitav_footer_center-div {
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 3rem;
    height: 10rem;
    /* border: 2px solid red; */
    /* gap: 1rem; */
  }

  .astitav_footer_links {
    /* border: 2px solid red; */
    display: flex;
    gap: 3rem;
    padding: 0 1rem;
  }
  .astitav_footer_links_list {
    /* border: 2px solid red; */
    display: grid;
    gap: 0.7rem;
    font-size: 0.9rem;
    font-weight: 700;
  }
  .astitav_footer_logo {
    font-size: 3.5rem;
    font-weight: 600;
    text-align: center;
    color: var(--color-dark-red);
  }
  .astitav_footer_btn {
    padding: 0.5rem 1rem;
    margin-inline: 0;
    /* margin-bottom: auto; */
    font-size: 0.875rem;
    text-align: center;
  }
  .astitav_footer_copyright-text {
    font-size: 0.9375rem;
    font-weight: 600;
    text-align: start;
    padding: 0 5rem;
  }
}

/* //For Desktop------------ */
@media screen and (min-width: 1150px) {
  .astitav_footer {
    overflow: hidden;
  }
  .astitav_footer_bg-desktop {
    display: block;
    width: 100%;
    background-color: var(--color-light-gray);
    position: absolute;
    bottom: 0;
    z-index: -5;
  }
  .astitav_footer_bg {
    display: none;
  }

  .astitav_footer_tree-sm {
    display: none;
  }

  .astitav_footer_bear {
    /* border: 2px solid red; */
    width: 20%;
  }

  .astitav_footer_tree-lg {
    width: 10%;
    display: block;
    /* border: 2px solid red; */
    margin-bottom: 0.5rem;
  }

  .astitav_footer_center-div {
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10rem 0 5rem;
    height: 18rem;
    /* gap: 1rem; */
  }

  .astitav_footer_links {
    /* border: 2px solid red; */
    display: flex;
    gap: 3rem;
    padding: 0 1rem;
  }
  .astitav_footer_links_list {
    /* border: 2px solid red; */
    display: grid;
    gap: 1rem;
    font-size: 0.9rem;
    font-weight: 700;
  }
  .astitav_footer_logo {
    font-size: 3.5rem;
    font-weight: 600;
    text-align: center;
    color: var(--color-dark-red);
  }
  .astitav_footer_btn {
    padding: 0.5rem 1rem;
    margin-inline: 0;
    /* margin-bottom: auto; */
    font-size: 0.875rem;
  }
  .astitav_footer_copyright-text {
    font-size: 0.9375rem;
    font-weight: 600;
    text-align: start;
    padding: 0 5rem;
  }
}
