.astitav_contact {
  padding: 4rem 1rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 3rem;
}
.astitav_contact_map {
  width: 100%;
  height: 22rem;
  border: 2px solid var(--color-gray);
}
.astitav_contact_form {
  width: 100%;
  display: grid;
  gap: 1rem;
}
.astitav_contact_form input {
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.5rem;
  width: 100%;
  border: 1px solid var(--color-gray);
  border-radius: 0.3rem;
}
.astitav_contact_form label {
  font-size: 0.7rem;
  font-weight: 600;
}
.astitav_contact_form_heading {
  font-size: 2.5rem;
  color: var(--color-dark-red);
  font-family: var(--font-family);
}
.astitav_contact_form_double-input-div {
  display: flex;
  gap: 1rem;
}
.astitav_contact_form_single-input-div {
  display: grid;
}
.astitav_contact_form button {
  margin: 1rem auto 0 auto;
  border: 2px solid var(--color-dark-red);
  color: var(--color-dark-red);
  background: none;
  font-family: var(--font-family);
  border-radius: 0.3125rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.astitav_contact_form button:hover {
  background-color: var(--color-dark-red);
  color: var(--color-white);

}

@media screen and (min-width: 1000px) {
  .astitav_contact {
    padding: 4rem 3rem;
    flex-direction: row;
  }
  .astitav_contact_map {
    width: 40rem;
    height: 33rem;
  }
  .astitav_contact_form {
    width: 45%;
  }

  .astitav_contact_form_heading {
    font-size: 3.125rem;
  }
  .astitav_contact_form_double-input-div {
    gap: 2rem;
  }
  .astitav_contact_form button {
    margin: 1rem auto 0 0;
  }
}
