.astitav_service-detail_header {
  background-color: var(--color-header-bg);
  text-align: center;
  padding: 4rem 1rem;
}

.astitav_service-detail_title,
.astitav_service-detail_title_short {
  font-family: var(--font-family);
  font-size: 2.5rem;
  color: var(--color-dark-red);
}
.astitav_service-detail_desc {
  font-size: 0.8rem;
  color: var(--color-black);
  font-weight: 600;
}

@media screen and (min-width: 1000px) {
  .astitav_service-detail_header {
    padding: 6rem 4rem;
  }

  .astitav_service-detail_title,
  .astitav_service-detail_title_short {
    font-size: 5rem;
  }
  .astitav_service-detail_desc {
    font-size: 1.1rem;
  }
}
