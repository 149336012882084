.astitav_believe {
  padding: 2rem;
  display: grid;
}
.astitav_believe_title,
.astitav_believe_desc1 {
  font-family: var(--font-family);
  font-size: 3.75rem;
  color: var(--color-dark-red);
  width: 50%;
  text-align: end;
}
.astitav_believe_desc1 {
  font-size: 1.5625rem;
  margin-inline-start: auto;
  width: 60%;
  text-align: start;
}
.astitav_believe_desc-div,
.astitav_believe_desc1_div {
  display: grid;
  gap: 1rem;
}
.astitav_believe_content {
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
}

.astitav_believe_desc2 {
  color: var(--color-text);
}

.astitav_believe_btn {
  border: 2px solid var(--color-dark-red);
  padding: 0.5rem 1rem;
  color: var(--color-dark-red);
  margin-inline-end: auto;
  border-radius: 0.5rem;
  font-size: 0.85rem;
  font-weight: 600;
  background: none;
  cursor: pointer;
  font-family: var(--font-family);

}
.astitav_believe_people-img {
  display: none;
}

@media screen and (min-width: 1000px) {
  .astitav_believe {
    margin: 2rem 0;
  }
  .astitav_believe_title,
  .astitav_believe_desc1 {
    font-size: 8.125rem;
  }
  .astitav_believe_desc1_div {
    width: 50%;
    margin-inline-start: auto;
  }
  .astitav_believe_desc1 {
    font-size: 4rem;
    width: 100%;
  }
  .astitav_believe_content {
    flex-direction: row;
  }
  .astitav_believe_desc-div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
  }
  .astitav_believe_desc-div_desktop {
    display: flex;
  }
  .astitav_believe_desc2 {
    font-size: 1.25rem;
  }

  .astitav_believe_btn {
    font-size: 0.9rem;
  }
  .astitav_believe_people-img {
    display: block;
  }
}
