.astitav_donation-content {
  position: relative;
}

.astitav_donation {
  padding: 2rem;
  display: grid;
  z-index: 8;
  position: relative;
}
.astitav_donation_title,
.astitav_donation_desc1 {
  font-family: var(--font-family);
  font-size: 1.5rem;
  color: var(--color-dark-red);
  width: 100%;
  text-align: start;
}
.astitav_donation_desc1 {
  font-size: 1.2rem;
  margin-inline-start: 0;
  width: 100%;
  text-align: start;
}
.astitav_donation_desc1_div {
  padding: 2rem 0;
}
.astitav_donation_account-details-div,
.astitav_donation_desc1_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.astitav_donation_content {
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
}

.astitav_donation_detail {
  color: var(--color-text);
  display: flex;
  gap: 0.3rem;
}
.astitav_donation_detail p {
  font-family: var(--font-family-inder);
}
.astitav_donation_detail span {
  margin-top: 0.2rem;
  font-size: 0.8rem;
  color: var(--color-dark-red);
}

.astitav_donation_btn {
  border: 2px solid var(--color-dark-red);
  padding: 0.5rem 1rem;
  color: var(--color-dark-red);
  margin-inline-end: auto;
  border-radius: 0.5rem;
  font-size: 0.85rem;
  font-weight: 600;
  background: none;
  cursor: pointer;
}
.astitav_donation_people-img {
  display: none;
}

.astitav_donation_barcode_div {
  /* border: 2px solid red; */
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
}
.astitav_donation_barcode {
  width: 60%;
}
.astitav_donation_barcode_small-title,
.astitav_donation_barcode_id {
  font-size: 0.9rem;
  font-weight: 500;
}
.astitav_donation_barcode_title {
  font-weight: bold;
  font-size: 1.4rem;
}

@media screen and (min-width: 1000px) {
  .astitav_donation {
    gap: 0rem;
    margin: 2rem 0;
  }
  .astitav_donation_title,
  .astitav_donation_desc1 {
    font-size: 2.5rem;
    text-align: end;
  }
  .astitav_donation_desc1_div {
    width: 50%;
    margin-inline-start: auto;
  }
  .astitav_donation_desc1 {
    font-size: 1.875rem;
    text-align: start;
  }
  .astitav_donation_content {
    display: flex;
    flex-direction: row;
  }
  .astitav_donation_account-details-div {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
  }
  .astitav_donation_account-details-div_desktop {
    display: flex;
  }
  .astitav_donation_detail {
    color: var(--color-text);
    font-size: 1.25rem;
  }

  .astitav_donation_btn {
    font-size: 0.9rem;
  }
  .astitav_donation_people-img {
    display: block;
  }
  .astitav_donation_detail span {
    margin-top: 0rem;
    line-height: 1.2;
    font-size: 1.5rem;
  }

  .astitav_donation_barcode_div {
    /* border: 2px solid red; */
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
  }
  .astitav_donation_barcode {
    width: 20%;
  }
  .astitav_donation_barcode_small-title,
  .astitav_donation_barcode_id {
    font-size: 0.9rem;
    font-weight: 500;
  }
  .astitav_donation_barcode_title {
    font-weight: bold;
    font-size: 1.4rem;
  }
  
}
