.astitav-history_container {
  padding: 3rem 6rem;
}
.astitav-history {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-image: url(../../assets/images/About/bg.png);
  background-repeat: no-repeat, repeat;
}
.astitav-history_image {
  width: 100%;
}
.astitav-history_content {
  display: flex;
  flex-direction: column;
  padding: 6rem;
}
.astitav-history_content-heading {
  color: var(--color-dark-red);
  font-family: var(--font-family);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 3rem;
}
.astitav-history_content-paragraph {
  color: #7b7575;
  font-family: var(--font-family-inder);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 1200px) {
  .astitav-history_content-heading {
    padding-bottom: 1rem;
  }
  .astitav-history_content-paragraph {
    font-size: 15px;
  }
}

@media screen and (max-width: 850px) {
  .astitav-history {
    flex-direction: column;
    background-repeat: repeat;
  }

  .astitav-history_image-img {
    max-width: 100%;
  }
}
