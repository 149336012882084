.astitav-about-who_container {
  background-color: var(--color-header-bg);
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  padding: 4rem 3rem;
}

.astitav-about-who_container-card_content {
  background-color: var(--color-white);
  width: 660px;
  height: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 4rem 3rem;
}

.astitav-about-who_container-card_content-heading {
  color: var(--color-dark-red);
  font-family: var(--font-family);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 2rem;
}

.astitav-about-who_container-card_content-paragraph {
  color: #7b7575;
  font-family: Inder;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 2rem;
}

.astitav-about-who_container-image-img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 1400px) {
  .astitav-about-who_container-card_content {
    width: 660px;
    padding: 3rem 2rem;
  }

  .astitav-about-who_container-card_content-heading {
    font-size: 40px;
    padding-bottom: 1rem;
  }

  .astitav-about-who_container-card_content-paragraph {
    font-size: 18px;
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 1200px) {
  .astitav-about-who_container {
    padding: 3rem;
  }

  .astitav-about-who_container-card_content {
    width: 100%;
    max-width: 660px;
    padding: 2rem;
  }

  .astitav-about-who_container-card_content-heading {
    font-size: 36px;
    padding-bottom: 1rem;
  }

  .astitav-about-who_container-card_content-paragraph {
    font-size: 16px;
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 850px) {
  .astitav-about-who_container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
}
