.astitav-container {
  position: relative;
  letter-spacing: 0.02em;
  background-color: var(--color-header-bg);
  /* height: 430px; */
  padding: 4rem 0;
  flex-shrink: 0;
  overflow: hidden;
}
.astitav-container_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}
.astitav-container_header-content {
  display: flex;
  flex-direction: column;
  padding: 6rem;
  width: 70%;
  gap: 1rem;
}
.astitav-container_header-content_heading {
  color: var(--color-dark-red);
  font-family: var(--font-family);
  font-size: 100px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}
.astitav-container_header-content_paragraph {
  color: var(--color-black);
  font-family: var(--font-family-inder);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.astitav-container_header-image_bg-img {
  /* position: absolute;
  right: 0;
  top: 0; */
  margin: auto 0;
  height: 500px;
  flex-shrink: 0;
  border-radius: 50%;
  margin-inline-end: 2rem;
}

@media screen and (max-width: 1100px) {
  .astitav-container_header-content {
    padding: 2rem;
    justify-content: center;
  }

  .astitav-container_header-image_bg-img {
    position: relative;
    right: auto;
    top: auto;
  }
}

@media screen and (max-width: 850px) {
  .astitav-container_header {
    flex-direction: column;
  }
  .astitav-container_header-content {
    width: 100%;
    align-items: center;
    text-align: center;
  }
  .astitav-container_header-content_heading {
    font-size: 58px;
  }
  .astitav-container_header-image_bg-img {
    margin-top: 0rem;
    height: 300px;
    flex-shrink: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
