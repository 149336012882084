.astitav_visit-us {
  padding: 2rem;
  display: grid;
}
.astitav_visit-us_title,
.astitav_visit-us_desc1 {
  font-family: var(--font-family);
  font-size: 3.75rem;
  color: var(--color-dark-red);
}
.astitav_visit-us_desc1 {
  font-size: 1.5625rem;
  margin-inline-start: auto;
  width: 70%;
  /* border: 2px solid red; */
}
.astitav_visit-us_first_div {
  display: grid;
  gap: 1rem;
}
.astitav_visit-us_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.astitav_visit-us_desc2 {
  color: var(--color-text);
}

.astitav_visit-us_btn {
  border: 2px solid var(--color-dark-red);
  padding: 0.5rem 1rem;
  color: var(--color-dark-red);
  margin-inline-end: auto;
  border-radius: 0.5rem;
  font-size: 0.85rem;
  font-weight: 600;
  background: none;
  cursor: pointer;
}
.astitav_visit-us_people-img {
  display: none;
}
.astitav_visit-us_second-div {
  width: 100%;
  text-align: center;
}
.astitav_visit-us_contact-form {
  width: 100%;
  display: grid;
  gap: 1.5rem;
}
.astitav_visit-us_contact-form_flex-div {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.astitav_visit-us_contact-form_date-time-div {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.astitav_visit-us_input {
  font-size: 0.8rem;
  padding: 0.5rem 0.8rem;
  border-radius: 0.315rem;
  border: 1px solid var(--color-gray);
  width: 100%;
}
.astitav_visit-us_input:focus {
  outline: none;
}
.astitav_visit-us_contact-div {
  display: grid;
  gap: 1rem;
  text-align: start;
  margin: 2rem 0;
}

.astitav_visit-us_contact-type-div {
  display: grid;
  gap: 0.2rem;
}
.astitav_visit-us_contact-title {
  font-size: 1.1rem;
  color: var(--color-dark-red);
  font-family: var(--font-family);
}
.astitav_visit-us_contact-desc {
  font-size: 0.7rem;
  font-weight: 600;
}

@media screen and (min-width: 850px) {
  .astitav_visit-us {
    gap: 0rem;
    margin: 2rem 0;
  }
  .astitav_visit-us_title,
  .astitav_visit-us_desc1 {
    font-size: 8.125rem;
    text-align: end;
    width: 50%;
  }
  .astitav_visit-us_first_div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
  }
  .astitav_visit-us_desc1 {
    font-size: 4rem;
    text-align: start;
    width: 100%;
    line-height: 0.5;
    /* height: fit-content; */
  }
  .astitav_visit-us_content {
    flex-direction: row-reverse;
  }

  .astitav_visit-us_desc2 {
    font-size: 1.25rem;
    width: 85%;
  }

  .astitav_visit-us_btn {
    font-size: 0.9rem;
  }
  .astitav_visit-us_people-img {
    display: block;
  }
  .astitav_visit-us_second-div {
    width: 40%;
  }
  .astitav_visit-us_contact-form {
    width: 90%;
  }
  .astitav_visit-us_contact-form_flex-div {
    flex-direction: row;
  }

  .astitav_visit-us_contact-div {
    gap: 2rem;
  }
}

@media screen and (min-width: 1100px) {
  .astitav_visit-us {
    gap: 0rem;
    margin: 2rem 0;
  }

  .astitav_visit-us_second-div {
    width: 25%;
  }
  .astitav_visit-us_contact-form {
    width: 75%;
  }
  .astitav_visit-us_contact-div {
    display: grid;
    gap: 2rem;
  }
  .astitav_visit-us_contact-type-div {
    display: grid;
    gap: 0.2rem;
  }
  .astitav_visit-us_contact-title {
    font-size: 1.1rem;
    color: var(--color-dark-red);
    font-family: var(--font-family);
  }
  .astitav_visit-us_contact-desc {
    font-size: 0.7rem;
    font-weight: 600;
  }
}
