.astitav_catering {
  padding: 2rem;
  display: grid;
  position: relative;
  /* border: 2px solid red; */
}
.astitav_catering_title,
.astitav_catering_desc {
  font-family: var(--font-family);
  font-size: 3.75rem;
  color: var(--color-dark-red);
  line-height: 1;
}
.astitav_catering_desc {
  font-size: 1.5625rem;
  margin-inline-start: auto;
  width: fit-content;
  /* border: 2px solid red; */
}
.astitav_catering_cards-div {
  padding: 2rem;
  display: grid;
  gap: 2rem;
}
.astitav_catring_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  padding: 1.5rem;
  padding-bottom: 2rem;
  border-radius: 1rem 1rem 30% 30%;
}
.astitav_catring_card-title {
  font-size: 1.3rem;
  font-weight: 600;
}
.astitav_catring_card-img {
  width: 100%;
  height: 10rem;
  object-fit: cover;
  overflow: hidden;
}
.astitav_catring_card-desc {
  font-size: 0.815rem;
  color: var(--color-text);
}
.astitav_catring_card-age-time {
  font-size: 0.75rem;
  font-weight: 600;
}
@media screen and (min-width: 550px) {
  .astitav_catering {
    padding: 2rem 1rem;
    gap: 0rem;
    margin: 2rem 0;
  }
  .astitav_catering_title,
  .astitav_catering_desc {
    font-size: 8.125rem;
    text-align: end;
    width: 30%;
  }
  .astitav_catering_desc {
    font-size: 3.125rem;
    text-align: start;
    width: 100%;
    width: 70%;
  }

  .astitav_catering_cards-div {
    padding: 1rem;
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 1000px) {
  .astitav_catering {
    padding: 2rem;
  }

  .astitav_catering_desc {
    margin-inline-start: auto;
  }

  .astitav_catering_cards-div {
    padding: 2rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .astitav_catring_card-title {
    font-size: 1.2rem;
  }
}
