.astitav_advantages {
  padding: 2rem 2rem 0 2rem;
  margin: 2rem 0;
  display: grid;
  background-color: var(--color-header-bg);
  position: relative;
}
.astitav_advantages_title,
.astitav_advantages_desc1 {
  font-family: var(--font-family);
  font-size: 3.75rem;
  color: var(--color-dark-red);
  z-index: 5;
  width: 50%;
  text-align: end;
  /* border: 2px solid red; */
}
.astitav_advantages_desc1 {
  font-size: 1.5625rem;
  margin-inline-start: auto;
  width: 65%;
  text-align: start;
  /* border: 2px solid red; */
}
.astitav_advantages_desc-div,
.astitav_advantages_desc1_div {
  display: grid;
  gap: 1rem;
}
.astitav_advantages_desc2 {
  color: var(--color-text);
}
.astitav_advantages_content {
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
}
.astitav_advantages_graphic {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 100%;
}
.astitav_advantages_people {
  position: relative;
  width: 100%;
}
.astitav_advantages_div {
  display: grid;
  grid-template-columns: auto;
  z-index: 5;
  gap: 2rem;
  place-items: center;
}
.astitav_advantages_advantage-div {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
}
.astitav_advantages_advantage-div img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.astitav_advantages_number7_logo {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 4rem;
  color: rgb(125, 27, 68);
}

.astitav_advantages_number1,
.astitav_advantages_number6,
.astitav_advantages_number2,
.astitav_advantages_number5,
.astitav_advantages_number7,
.astitav_advantages_number3,
.astitav_advantages_number4 {
  font-size: 9rem;
  color: var(--color-white);
  font-weight: 600;
  position: relative;
  width: 100%;
  line-height: 1;
}
.astitav_advantages_advantage-title {
  font-size: 1.2rem;
  font-weight: 600;
}

@media screen and (min-width: 1000px) {
  .astitav_advantages {
    padding: 2rem;
  }
  .astitav_advantages_title,
  .astitav_advantages_desc1 {
    font-size: 8.125rem;
    width: 60%;
  }
  .astitav_advantages_desc1_div {
    width: 70%;
    margin-inline-start: auto;
  }
  .astitav_advantages_desc1 {
    font-size: 4rem;
    width: 100%;
  }
  .astitav_advantages_desc-div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
  }

  .astitav_advantages_desc2 {
    font-size: 1.25rem;
    width: 85%;
  }
  .astitav_advantages_content {
    flex-direction: row;
  }

  .astitav_advantages_graphic {
    width: auto;
  }
  .astitav_advantages_people {
    position: absolute;
    bottom: 0;
    right: 0;
    width: auto;
  }
  .astitav_advantages_div {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .astitav_advantages_number1,
  .astitav_advantages_number6 {
    font-size: 10rem;
  }
  .astitav_advantages_number2,
  .astitav_advantages_number5,
  .astitav_advantages_number7 {
    font-size: 8rem;
  }
  .astitav_advantages_number3 {
    font-size: 12.5rem;
    line-height: 0.9;
  }
  .astitav_advantages_number4 {
    font-size: 7.5rem;
    line-height: 1.3;
  }
  .astitav_advantages_advantage-title {
    font-size: 1.3rem;
    font-weight: 600;
  }
}
