.astitav__service-about {
  padding: 4rem 0rem;
}
.astitav_service-about_header {
  padding: 0rem 0.8rem;
  position: relative;
}
.astitav_service-about_header-title,
.astitav_service-about_header-small-title {
  font-size: 4.125rem;
  color: var(--color-dark-red);
  font-family: var(--font-family);
}
.astitav_service-about_header-title {
  width: 100%;
  text-align: center;
}

.astitav_service-about_header-desc-div {
  width: 100%;
  margin-inline-start: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}
.astitav_service-about_header-small-title {
  font-size: 1.5rem !important;
}
.astitav_service-about_header-small-title span {
  text-transform: lowercase;
  font-family: inherit;
}
.astitav_service-about_header-desc {
  width: 90%;
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--color-gray);
}
.astitav_service-about_header-btns {
  display: flex;
  gap: 2rem;
}
.astitav_service-about_header-btns button {
  border: 2px solid var(--color-dark-red);
  width: 6rem;
  color: var(--color-dark-red);
  background: none;
  font-family: var(--font-family);
  border-radius: 0.3125rem;
  text-align: center;
  padding: 0.5rem 0rem;
  cursor: pointer;
}
.astitav_service-about_header-btns button:hover {
  background-color: var(--color-dark-red);
  color: var(--color-white);
}

.astitav_service-about_content {
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  padding: 4rem 0rem;
}
.astitav_service-about_content_text-div {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: var(--color-gray);
  font-size: 1rem;
  font-weight: 400;
  padding: 0 0.8rem;
}
.astitav_service-about_content_text-div p {
  font-family: var(--font-family-inder) !important;
}
.astitav_service-about_content_points {
  display: grid;
  gap: 0.3rem;
}
.astitav_service-about_content_points p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.astitav_service-about_content_points span {
  color: var(--color-orange);
  font-size: 1rem;
}
.astitav_service-about_content_img-div {
  flex: 1;
  display: flex;
  align-items: center;
  /* border: 2px solid red; */
}
.astitav_service-about_content_img-div img {
  width: 100%;
}

.astitav_service-about_other-content_div {
  display: grid;
  grid-template-columns: auto;
  place-items: center;
  row-gap: 3rem;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.astitav_service-about_other-service-div {
  display: grid;
  gap: 1rem;
}
.astitav_service-about_other-service-div img {
  width: 80%;
  margin: 0 auto;
}
.astitav_service-about_other-content_div p {
  font-size: 1.5rem;
  color: var(--color-dark-red);
  font-family: var(--font-family);
}

.astitav_service-about_header::before,
.astitav_service-about_header::after {
  content: " ";
  background-color: var(--color-aqua);
  padding: 0.8rem;
  position: absolute;
  border-radius: 50%;
  left: 3rem;
  top: 0rem;
  margin-inline-start: auto;
}
.astitav_service-about_header::after {
  padding: 0.6rem;
  background-color: var(--color-orange-red);
  right: 2rem;
  left: auto;
  top: auto;
  bottom: 13rem;
}

@media screen and (min-width: 800px) {
  .astitav_service-about_content_text-div {
    width: 70%;
    margin: 0 auto;
  }
  .astitav_service-about_content_img-div img {
    width: 70%;
    margin: 0 auto;
  }

  .astitav_service-about_other-content_div {
    grid-template-columns: auto auto;
    row-gap: 4rem;
    width: 90%;
    margin: 0 auto;
    text-align: start;
  }
  .astitav_service-about_other-service-div {
    display: grid;
    place-items: center;
  }
  .astitav_service-about_other-service-div img {
    width: 80%;
    margin: 0 auto;
  }
  .astitav_service-about_other-content_div p {
    font-size: 1.8rem;
    color: var(--color-dark-red);
    font-family: var(--font-family);
  }
}

@media screen and (min-width: 1100px) {
  .astitav__service-about {
    padding: 4rem 0rem;
  }
  .astitav_service-about_header {
    padding: 0rem;
    position: relative;
  }

  .astitav_service-about_header::before,
  .astitav_service-about_header::after {
    padding: 1.5rem;
    left: 5rem;
    top: 9rem;
  }
  .astitav_service-about_header::after {
    padding: 2rem;
    right: 5rem;
    left: auto;
    top: auto;
    bottom: 10rem;
  }

  .astitav_service-about_header-title,
  .astitav_service-about_header-small-title {
    font-size: 8.125rem;
  }
  .astitav_service-about_header-title {
    width: 30%;
    text-align: end;
    margin-inline-start: 0.5rem;
  }

  .astitav_service-about_header-desc-div {
    width: 78%;
    margin-inline-start: auto;
    align-items: flex-start;
    text-align: start;
    flex-direction: column;
  }
  .astitav_service-about_header-small-title {
    font-size: 3.125rem !important;
  }
  .astitav_service-about_header-desc {
    width: 80%;
    font-size: 1.25rem;
  }
  .astitav_service-about_header-btns button {
    font-size: 1.2rem;
    width: 8rem;
  }
  .astitav_service-about_content {
    flex-direction: row;
    gap: 2.5rem;
    padding: 6rem 4rem;
  }
  .astitav_service-about_content_text-div {
    font-size: 1.25rem;
    padding: 0;
    width: auto;
  }
  .astitav_service-about_content_points {
    gap: 0.5rem;
  }
  .astitav_service-about_content_points span {
    font-size: 1.5rem;
  }
  .astitav_service-about_content_img-div img {
    width: 100%;
  }

  .astitav_service-about_other-content_div {
    grid-template-columns: auto auto;
    row-gap: 4rem;
    width: 90%;
    margin: 0 auto;
    text-align: start;
  }
  .astitav_service-about_other-service-div {
    display: grid;
    place-items: start;
  }
  .astitav_service-about_other-service-div img {
    width: 90%;
    margin: 0 auto 0 0;
  }
  .astitav_service-about_other-content_div p {
    font-size: 1.8rem;
    color: var(--color-dark-red);
    font-family: var(--font-family);
  }
}
