.astitav-advisor_main-div{
    margin: 2rem 0;
}
.astitav-advisor_container {
  padding: 6rem;
  background-image: url(../../assets/images/About/bg.png);
  background-repeat: repeat;
}
.astitav-advisor {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}
.astitav-advisor1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.astitav-advisor_image {
  width: 100%;
}
.astitav-advisor_content {
  display: flex;
  flex-direction: column;
  padding: 6rem;
}
.astitav-advisor_content-heading {
  color: var(--color-dark-red);
  font-family: var(--font-family);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 2rem;
}
.astitav-advisor_content-heading1 {
  color: var(--color-dark-red);
  font-family: var(--font-family-inder);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 1rem;
}
.astitav-advisor_content-paragraph {
  color: #7b7575;
  font-family: var(--font-family-inder);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 1200px) {
  .astitav-advisor_content-heading {
    padding-bottom: 1rem;
  }
  .astitav-advisor_content-paragraph {
    font-size: 15px;
  }
}

@media screen and (max-width: 850px) {
  .astitav-advisor_container {
    padding: 1rem 1rem;
    text-align: center;
    background-image: none;
  }
  .astitav-advisor {
    flex-direction: column;
    background-image: url(../../assets/images/About/bg.png);
    background-repeat: repeat;
  }
  .astitav-advisor_content {
    padding: 1rem;
  }

  .astitav-advisor1 {
    flex-direction: column;
    background-image: url(../../assets/images/About/bg.png);
    background-repeat: repeat;
  }

  .astitav-advisor_image-img {
    width: 50%;
  }
  .astitav-advisor_content-heading {
    font-size: 2.2rem;
    padding-bottom: 0.5rem;
  }
  .astitav-advisor_content-heading1 {
    font-size: 1.5rem;
    padding-bottom: 1rem;
  }

  .astitav-advisor_content-paragraph {
    font-size: 0.9rem;
  }
}
