.astitav_parents {
  padding: 4rem 1rem;
  display: grid;
  gap: 2rem;
}
.astitav_parents_title {
  color: var(--color-dark-red);
  font-family: var(--font-family);
  font-size: 3.25rem;
  text-align: center;
}
.astitav_parents_cards-div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}
.astitav_parents_card {
  width: 20rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 0.5rem;
  padding: 3rem;
  background-color: var(--color-news-bg);
}
.astitav_parents_card-cactus-img {
  display: none;
}
.astitav_parents_card-title {
  font-family: var(--font-family-inder);
  font-size: 1.4rem;
  font-weight: 400;
}
.astitav_parents_card-desc {
  color: var(--color-gray);
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
}

@media screen and (min-width: 1000px) {
  .astitav_parents {
    padding: 0rem;
    gap: 4rem;
  }
  .astitav_parents_title {
    font-size: 6.25rem;
  }
  .astitav_parents_card,
  .astitav_parents_card-cactus-img {
    width: 25rem;
    height: 25rem;
    gap: 0.8rem;
    padding: 4rem;
    background-color: transparent;
  }
  .astitav_parents_card-cactus-img {
    display: block;
  }
  .astitav_parents_card-title {
    font-size: 1.875rem;
  }
  .astitav_parents_card-desc {
    font-size: 0.9375rem;
  }
  .astitav_parents_card:nth-child(even) {
    background-color: var(--color-news-bg);
  }
}
