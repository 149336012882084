.astitav_believe_video-container {
  position: relative;
  text-align: center;
}
.astitav_believe_tvframe {
  width: 90%;
}
.astitav_believe_video {
  position: absolute;
  left: 0;
  right: 0;
  top: 1.5rem;
  bottom: 0;
  margin: auto;
  width: 72%;
  height: 55%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 2rem;
  cursor: pointer;
}
@media screen and (min-width: 690px) {
  .astitav_believe_video-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    margin-inline: auto;
  }
  .astitav_believe_tvframe {
    margin-block: auto;
  }
  .astitav_believe_video {
    top: 2rem;
    width: 70.5%;
    height: 10rem;
    background-color: var(--color-dark-red);
  }
}

@media screen and (min-width: 1000px) {
  .astitav_believe_tvframe {
    width: 90%;
  }
  .astitav_believe_video {
    top: 3rem;
    height: 13rem;
  }
}

@media screen and (min-width: 1350px) {
  .astitav_believe_video {
    top: 3rem;
    height: 16rem;
  }
}
