.astitav_admission-steps {
  padding: 4rem 1rem;
}
.astitav_admission-steps_header {
  padding: 0rem 0.8rem;
  position: relative;
}
.astitav_admission-steps_header-title,
.astitav_admission-steps_header-small-title {
  font-size: 4.125rem;
  color: var(--color-dark-red);
  font-family: var(--font-family);
}
.astitav_admission-steps_header-title {
  width: 100%;
  text-align: center;
}

.astitav_admission-steps_header-desc-div {
  width: 100%;
  margin-inline-start: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}
.astitav_admission-steps_header-small-title {
  font-size: 1.5rem !important;
}

.astitav_admission-steps_header-desc {
  width: 90%;
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--color-gray);
}
.astitav_service-about_header::before,
.astitav_service-about_header::after {
  content: " ";
  background-color: var(--color-aqua);
  padding: 0.8rem;
  position: absolute;
  border-radius: 50%;
  left: 3rem;
  top: 0rem;
  margin-inline-start: auto;
}
.astitav_service-about_header::after {
  padding: 0.6rem;
  background-color: var(--color-orange-red);
  right: 2rem;
  left: auto;
  top: auto;
  bottom: 10rem;
}

.astitav_admission-steps_steps-div {
  padding: 3rem 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}
.astitav_admission-steps_step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: fit-content;
  min-width: 14rem;
}

.astitav_admission-steps_step-num1,
.astitav_admission-steps_step-num2,
.astitav_admission-steps_step-num3 {
  width: 7rem;
  height: 7rem;
  font-size: 3rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--color-white);
}
.astitav_admission-steps_step-num1 p,
.astitav_admission-steps_step-num2 p,
.astitav_admission-steps_step-num3 p {
  opacity: 0.5;
}
.astitav_admission-steps_step-num1 {
  background-color: var(--color-orange);
}
.astitav_admission-steps_step-num2 {
  background-color: var(--color-teal);
}
.astitav_admission-steps_step-num3 {
  background-color: var(--color-purple-dark);
}

.astitav_admission-steps_step-title1,
.astitav_admission-steps_step-title2,
.astitav_admission-steps_step-title3 {
  line-height: 0;
  font-size: 1.25rem;
  font-weight: 600;
}
.astitav_admission-steps_step-title1 {
  color: var(--color-orange);
}
.astitav_admission-steps_step-title2 {
  color: var(--color-teal);
}
.astitav_admission-steps_step-title3 {
  color: var(--color-purple-dark);
}
.astitav_admission-steps_step-desc {
  font-size: 0.8rem;
  width: 60%;
  text-align: center;
  margin: 0 auto;
  color: var(--color-gray);
}

@media screen and (min-width: 850px) {
  .astitav_admission-steps {
    padding: 4rem;
  }
  .astitav_admission-steps_header {
    padding: 0rem;
    position: relative;
  }

  .astitav_admission-steps_header::before,
  .astitav_admission-steps_header::after {
    padding: 1.5rem;
    left: 5rem;
    top: 9rem;
  }
  .astitav_admission-steps_header::after {
    padding: 2rem;
    right: 5rem;
    left: auto;
    top: auto;
    bottom: 10rem;
  }

  .astitav_admission-steps_header-title,
  .astitav_admission-steps_header-small-title {
    font-size: 8.125rem;
  }
  .astitav_admission-steps_header-title {
    width: 30%;
    text-align: end;
    margin-inline-start: 0.5rem;
  }

  .astitav_admission-steps_header-desc-div {
    width: 78%;
    margin-inline-start: auto;
    align-items: flex-start;
    text-align: start;
    flex-direction: column;
  }
  .astitav_admission-steps_header-small-title {
    font-size: 3.125rem !important;
  }
  .astitav_admission-steps_header-desc {
    width: 80%;
    font-size: 1.25rem;
  }

  .astitav_admission-steps_steps-div {
    padding: 6rem 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    gap: 0rem;
  }
}
