.astitav_how-to-apply {
  padding: 4rem 0;
}
.astitav_how-to-apply_cards-div {
  display: flex;
  flex-direction: column; /*--change--*/
  align-items: center;
  justify-content: center;
}
.astitav_how-to-apply_cards-div p {
  font-family: var(--font-family-inder);
}
.astitav_how-to-apply_card,
.astitav_how-to-apply_center-card {
  text-align: center;
  width: 80%; /*--change--*/
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  padding: 3rem; /*--change--*/
  /* border: 2px solid red; */
  background-color: var(--color-news-bg);
  z-index: 5;
}
.astitav_how-to-apply_center-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 1rem;
  background-color: var(--color-white);
  z-index: 8;
  width: 90%; /*--change--*/
}

.astitav_how-to-apply_card-title {
  font-size: 1.7rem; /*--change--*/
  font-weight: 500;
}
.astitav_how-to-apply_card-desc {
  font-size: 0.8rem;
  color: var(--color-gray);
  font-weight: 600;
}

.astitav_how-to-apply_steps-div {
  padding: 3rem 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}
.astitav_how-to-apply_step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: fit-content;
  min-width: 14rem;
  z-index: 8;
}

.astitav_how-to-apply_step-num1,
.astitav_how-to-apply_step-num2,
.astitav_how-to-apply_step-num3 {
  width: 7rem;
  height: 7rem;
  font-size: 3rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--color-white);
}
.astitav_how-to-apply_step-num1 p,
.astitav_how-to-apply_step-num2 p,
.astitav_how-to-apply_step-num3 p {
  opacity: 0.5;
}
.astitav_how-to-apply_step-num1 {
  background-color: var(--color-orange);
}
.astitav_how-to-apply_step-num2 {
  background-color: var(--color-teal);
}
.astitav_how-to-apply_step-num3 {
  background-color: var(--color-purple-dark);
}

.astitav_how-to-apply_step-title1,
.astitav_how-to-apply_step-title2,
.astitav_how-to-apply_step-title3 {
  line-height: 0;
  font-size: 1.25rem;
  font-weight: 600;
}
.astitav_how-to-apply_step-title1 {
  color: var(--color-orange);
}
.astitav_how-to-apply_step-title2 {
  color: var(--color-teal);
}
.astitav_how-to-apply_step-title3 {
  color: var(--color-purple-dark);
}
.astitav_how-to-apply_step-desc {
  font-size: 0.8rem;
  width: 60%;
  text-align: center;
  margin: 0 auto;
  color: var(--color-gray);
}

.astitav_how-to-apply_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 3rem 0;
  background-color: var(--color-news-bg);
}
.astitav_how-to-apply_form-sections-div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 90%;
}
.astitav_how-to-apply_parent-form,
.astitav_how-to-apply_child-form {
  width: 100%;
  display: grid;
  gap: 1rem;
}

.astitav_how-to-apply_form-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
}
.astitav_how-to-apply_form-input-div {
  display: grid;
  gap: 0.3rem;
}
.astitav_how-to-apply_form-input-div label {
  font-size: 0.8rem;
  font-weight: 500;
}
.astitav_how-to-apply_form-input-div input {
  border: none;
  padding: 1rem;
}
.astitav_how-to-apply_form-input-div span {
  color: var(--color-red);
}
.astitav_how-to-apply_form-agree-tick {
  margin-inline-start: 1.5rem;
  margin-inline-end: auto;
  line-height: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.7rem;
  font-weight: 600;
}
.astitav_how-to-apply_form button {
  border: 2px solid var(--color-dark-red);
  width: 15rem;
  font-size: 1rem;
  color: var(--color-dark-red);
  background: none;
  font-family: var(--font-family);
  border-radius: 0.3125rem;
  text-align: center;
  padding: 0.5rem 0rem;
  cursor: pointer;
  margin-top: 1rem;
}
.astitav_how-to-apply_form button:hover {
  background-color: var(--color-dark-red);
  color: var(--color-white);
}

@media screen and (min-width: 850px) {
  .astitav_how-to-apply_cards-div {
    flex-direction: row;
  }
  .astitav_how-to-apply_card,
  .astitav_how-to-apply_center-card {
    width: 30%;
    gap: 1rem;
    padding: 4rem;
  }
  .astitav_how-to-apply_center-card {
    z-index: 8;
  }

  .astitav_how-to-apply_card-title {
    font-size: 1.875rem;
    font-weight: 400;
  }
  .astitav_how-to-apply_card-desc {
    font-size: 0.9375rem;
  }

  .astitav_how-to-apply_steps-div {
    padding: 6rem 0;
    flex-direction: row;
    gap: 0rem;
  }

  .astitav_how-to-apply_form {
    padding: 4rem 0;
  }
  .astitav_how-to-apply_form-sections-div {
    flex-direction: row;
    gap: 4rem;
    width: 85%;
  }
  .astitav_how-to-apply_parent-form,
  .astitav_how-to-apply_child-form {
    width: 50%;
    gap: 2rem;
  }

  .astitav_how-to-apply_form-title {
    font-size: 1.875rem;
  }
  .astitav_how-to-apply_form-agree-tick {
    margin-inline-start: 6.5rem;
  }
}
