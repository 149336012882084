@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inder&display=swap");

@font-face {
  font-family: "Sensei";
  src: url("./assets/fonts/sensei-medium-webfont.woff2") format("woff2"),
    url("./assets/fonts/sensei-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

:root {
  --font-family: "Sensei";
  --font-family-poppins: "Poppins";
  --font-family-inder: "Inder", sans-serif;
  --color-dark-red: #7d1b44;
  --color-white: #ffffff;
  --color-black: #080808;
  --color-light-gray: #f0f0f0;
  --color-header-bg: #d5e6eb;
  --color-text: #383838;
  --color-gray: #7b7575;
  --color-news-bg: #f4f1ea;
  --color-cream: #ffdfc0;
  --color-purple: #cb9cff;
  --color-orange-red: #fa7b7a;
  --color-red: #ff0000;
  --color-aqua: #57b4aa;
  --color-orange: #e74c25;
  --color-teal: #167287;
  --color-purple-dark: #7048b1;
  --color-green: #00d63d9e;
}
