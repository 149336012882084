.astitav_internship_content {
  position: relative;
}

.astitav_internship_form {
  width: 90%;
  padding: 4rem 0;
  margin: 0 auto;
  display: grid;
  gap: 2rem;
  z-index: 8;
}
.astitav_internship_form input {
  width: 100%;
  padding: 1rem;
  border-radius: 0.3125rem;
  border: 1px solid var(--color-gray);
}
.astitav_internship_form_desc {
  font-family: var(--font-family-inder);
  font-size: 1rem;
}
.astitav_internship_form_double-input-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.astitav_internship_form_radio-div {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.astitav_internship_form_radio-div-title {
  font-size: 0.85rem;
  font-weight: 600;
}
.astitav_internship_form_input-div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.astitav_internship_form_input-div label {
  font-size: 0.9rem;
}

.astitav_internship_form button {
  border: 1px solid var(--color-dark-red);
  padding: 0.8rem 1.5rem;
  color: var(--color-dark-red);
  background-color: var(--color-white);
  margin-inline-end: 0;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  background: none;
  font-family: var(--font-family);
  cursor: pointer;
  margin: 0 auto;
  transition: all 0.2s ease-in-out;
}
.astitav_internship_form button:hover {
  background-color: var(--color-dark-red);
  color: var(--color-white);
}

@media screen and (min-width: 700px) {
  .astitav_internship_form {
    width: 75%;
    padding: 4rem;
  }
  .astitav_internship_form_desc {
    font-size: 1.25rem;
  }
  .astitav_internship_form_double-input-div {
    flex-direction: row;
  }
  .astitav_internship_form_radio-div-title {
    font-size: 0.75rem;
  }
  .astitav_internship_form_input-div label {
    font-size: 0.8rem;
  }

  .astitav_internship_form button {
    margin: 0;
    margin-inline-end: auto;
  }
}
